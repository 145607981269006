/* src/MediaPlayer.css */

.media-player {
    width: 80%;
    margin: 50px auto;
  }
  
  .controls {
    display: flex;
    align-items: center;
  }
  
  button {
    background: #444;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  input[type="range"] {
    flex-grow: 1;
    margin-right: 10px;
  }
  